<template>
    <custom-vue-table class="pl-3 pr-3" title="Purchase Entry" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                      :extra-params="{ start_date, vendor, end_date }">
        <template #filters>
            <div class="btn-group ml-3">
                <drop-btn text="Filters" class="ml-3" icon="fa fa-filter" size="md" dropdown-width="30r">
                    <div class="p-3">
                        <validated-ajax-vue-select name="Vendor" :url="vendorOptions"
                                                   label="Vendor"
                                                   v-model="vendor"/>
                        <validated-input type="date" label="Start Date" name="Start Date"
                                               v-model="start_date"/>
                        <validated-input type="date" label="End Date" name="End Date"
                                               v-model="end_date"/>
                    </div>
                </drop-btn>
                <btn class="ml-3" @click="$router.push('/warehouse/entry/add/')" size="md" text="Add"
                     icon="fa fa-plus"/>
            </div>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
            </div>
        </template>
    </custom-vue-table>
</template>

<script>
import urls from '../../../../data/urls';

export default {
    name  : 'ListingComponent',
    props : {
        start_date : { type : String },
        end_date   : { type : String },
        vendor     : { type : String },
        status     : { type : String }
    },
    data () {
        return {
            loading       : false,
            listUrl       : urls.warehouseAdmin.purchaseEntry.list,
            vendorOptions : urls.warehouseAdmin.vendorOptions,
            fields        : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'Purchase ID'
                },
                {
                    name      : 'order_id',
                    sortField : 'order_id',
                    title     : 'PO ID'
                },
                {
                    name      : 'vendor',
                    sortField : 'vendor',
                    title     : 'Vendor'
                },
                {
                    name      : 'date',
                    sortField : 'date',
                    title     : 'Date'
                },
                {
                    name      : 'created_by',
                    sortField : 'created_by',
                    title     : 'Created By'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewDetails (rowData) {
            if (rowData.status === 'Draft') {
                this.$router.push('/warehouse/entry/' + rowData.order_id + '/add/');
            } else {
                this.$router.push('/warehouse/entry/' + rowData.id + '/details/');
            }
        }
    }
};
</script>
